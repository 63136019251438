import styles from "../../styles/BlogsAndNotes.module.css";
import Title from "../../components/BlogsAndNotes/Title";
import { MathComponent } from "mathjax-react";

interface TimelineProps {
    current: Array<String>;
}

const Timeline = ({ current }: TimelineProps) => {

    return (
        <div style={{ marginTop: "2rem", paddingBottom: "2rem", textAlign: "left" }}>
            <div className={styles.font}>
                These are the books I've read most recently.
                I will try to rate them all and write short reviews. Sometimes I read multiple books
                at the same time so it might not be the case that I read book <MathComponent display={false} tex={String.raw`b_{i}`}></MathComponent> in <MathComponent display={false} tex={String.raw`t_{i} - t_{i-1}`}></MathComponent> days
                where <MathComponent display={false} tex={String.raw`t_{i}`}></MathComponent> is the date I finished <MathComponent display={false} tex={String.raw`b_{i}`}></MathComponent>.
                Note that clicking on the bolded titles will take you to my review and rating. Reviews for the
                other books are forthcoming.
                <p>Currently reading: <i>{current[0]}</i> by {current[1]}</p>
            </div>
            <div style={{ marginBottom: "1rem", marginTop: "4rem" }}>
                <Title
                    date={""}
                    text={"Fall 2024"}
                    title={""}
                    small={false}
                    pdf={null}
                />
                <div style={{ marginLeft: '2rem' }}>
                <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"December 11, 2024"}
                            text={"Foundations of Statistical Natural Language Processing by Christopher D. Manning et al."}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"December 11, 2024"}
                            text={"Speech and Language Processing: An Introduction to NLP, Computational Linguistics and Speech Recognition by Dan Jurafsky et al."}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"November 22, 2024"}
                            text={"The Diary of a Young Girl by Anne Frank"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"November 11, 2024"}
                            text={"Probability and Statistics: The Science of Uncertainty by Michael J. Evans"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                </div>
            </div>
            <div style={{ marginBottom: "1rem"}}>
                <Title
                    date={""}
                    text={"Summer 2024"}
                    title={""}
                    small={false}
                    pdf={null}
                />
                <div style={{ marginLeft: '2rem' }}>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"August 16, 2024"}
                            text={"Alice's Adventures in Wonderland by Lewis Carroll"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"August 15, 2024"}
                            text={"Analysis on Manifolds by James R. Munkres"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"August 7, 2024"}
                            text={"The Sound and the Fury by William Faulkner"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"July 9, 2024"}
                            text={"UNIX System Programming by Haviland et al."}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                </div>
                <Title
                    date={""}
                    text={"Spring 2024"}
                    title={""}
                    small={false}
                    pdf={null}
                />
                <div style={{ marginLeft: '2rem' }}>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"June 4, 2024"}
                            text={"The Picture of Dorian Grey by Oscar Wilde"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"June 3, 2024"}
                            text={"Computational Complexity: A Modern Approach by Sanjeev Arora and Boaz Barak"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"May 29, 2024"}
                            text={"Introduction to Algorithms by Ronald Rivest et al"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"May 26, 2024"}
                            text={"The Odyssey by Homer"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"May 19, 2024"}
                            text={"The Sun Also Rises by Ernest Hemingway"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"May 3, 2024"}
                            text={"On the Road by Jack Kerouac"}
                            title={"road"}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"March 29, 2024"}
                            text={"The Adventures of Huckleberry Finn by Mark Twain"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                </div>
            </div>

            <div style={{ marginBottom: "1rem", marginTop: "2rem" }}>

                <Title
                    date={""}
                    text={"Winter 2023-2024"}
                    title={""}
                    small={false}
                    pdf={null}
                />
                <div style={{ marginLeft: '2rem' }}>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"March 17, 2024"}
                            text={"The Prince by Niccolò Machiavelli"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"March 14, 2024"}
                            text={"Cat's Cradle by Kurt Vonnegut"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"March 10, 2024"}
                            text={"Jane Eyre by Charlotte Brontë"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"February 16, 2024"}
                            text={"Frankenstein by Mary Shelley"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"January 5, 2024"}
                            text={"One Hundred Years of Solitude by Gabriel García Márquez"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"December 27, 2023"}
                            text={"Death of a Salesman by Arthur Miller"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"December 25, 2023"}
                            text={"A Shining by Jon Fosse"}
                            title={"shining"}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"December 25, 2023"}
                            text={"The Sea by John Banville"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"December 22, 2023"}
                            text={"Catch-22 by Joseph Heller"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                </div>
                <div style={{ marginBottom: "1rem", marginTop: "2rem" }}>
                    <Title
                        date={""}
                        text={"Fall 2023"}
                        title={""}
                        small={false}
                        pdf={null}
                    />
                    <div style={{ marginLeft: '2rem' }}>
                        <div style={{ marginTop: '1rem' }}>
                            <Title
                                date={"December 15, 2023"}
                                text={"A Beautiful Mind by Sylvia Nasar"}
                                title={""}
                                small={true}
                                pdf={null}
                            />
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                            <Title
                                date={"December 4, 2023"}
                                text={"Introduction to the Theory of Computation by Michael Sipser"}
                                title={""}
                                small={true}
                                pdf={null}
                            />
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                            <Title
                                date={"October 5, 2023"}
                                text={"Tortilla Flat by John Steinbeck"}
                                title={""}
                                small={true}
                                pdf={null}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: "1rem", marginTop: "2rem" }}>
                    <Title
                        date={""}
                        text={"Summer 2023"}
                        title={""}
                        small={false}
                        pdf={null}
                    />
                    <div style={{ marginLeft: '2rem' }}>
                        <div style={{ marginTop: '1rem' }}>
                            <Title
                                date={"September 21, 2023"}
                                text={"Mrs. Dalloway by Virginia Woolf"}
                                title={""}
                                small={true}
                                pdf={null}
                            />
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                            <Title
                                date={"September 10, 2023"}
                                text={"Infinite Jest by David Foster Wallace"}
                                title={""}
                                small={true}
                                pdf={null}
                            />
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                            <Title
                                date={"August 28, 2023"}
                                text={"Foundation by Isaac Asimov"}
                                title={""}
                                small={true}
                                pdf={null}
                            />
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                            <Title
                                date={"August 15, 2023"}
                                text={"Clean Architecture by Robert C. Martin"}
                                title={""}
                                small={true}
                                pdf={null}
                            />
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                            <Title
                                date={"August 12, 2023"}
                                text={"The Crucible by Arthur Miller"}
                                title={""}
                                small={true}
                                pdf={null}
                            />
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                            <Title
                                date={"August 10, 2023"}
                                text={"Hamlet by William Shakespeare"}
                                title={""}
                                small={true}
                                pdf={null}
                            />
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                            <Title
                                date={"July 13, 2023"}
                                text={"Oliver Twist by Charles Dickens"}
                                title={""}
                                small={true}
                                pdf={null}
                            />
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                            <Title
                                date={"July 4, 2023"}
                                text={"Topology by James R. Munkres"}
                                title={""}
                                small={true}
                                pdf={null}
                            />
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                            <Title
                                date={"July 3, 2023"}
                                text={"The Death of Ivan Ilych by Leo Tolstoy"}
                                title={""}
                                small={true}
                                pdf={null}
                            />
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                            <Title
                                date={"July 2, 2023"}
                                text={"One Flew Over the Cuckoo's Nest by Ken Kesey"}
                                title={""}
                                small={true}
                                pdf={null}
                            />
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                            <Title
                                date={"June 26, 2023"}
                                text={"The Time Machine by H. G. Wells"}
                                title={""}
                                small={true}
                                pdf={null}
                            />
                        </div>
                        <div style={{ marginTop: '1rem', marginBottom: "2rem" }}>
                            <Title
                                date={"June 25, 2023"}
                                text={"War and Peace Volume 3 by Leo Tolstoy"}
                                title={"warpeace"}
                                small={true}
                                pdf={null}
                            />
                        </div>
                    </div>
                </div>
                <Title
                    date={""}
                    text={"Spring 2023"}
                    title={""}
                    small={false}
                    pdf={null}
                />
                <div style={{ marginLeft: '2rem' }}>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"June 17, 2023"}
                            text={"The Merchant of Venice by William Shakespeare"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"June 12, 2023"}
                            text={"The Catcher in the Rye by J.D. Salinger"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"June 10, 2023"}
                            text={"Cannery Row by John Steinbeck"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"June 8, 2023"}
                            text={"Klara and the Sun by Kazuo Ishiguro"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"June 2, 2023"}
                            text={"The Pearl By John Steinbeck"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"June 2, 2023"}
                            text={"War and Peace Volume 2 by Leo Tolstoy"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"May 24, 2023"}
                            text={"The Handmaid's Tale by Margaret Atwood"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"May 21, 2023"}
                            text={"The Metamorphosis and Other Stories by Franz Kafka"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"May 16, 2023"}
                            text={"The Hitchhiker's Guide to the Galaxy by Douglas Adams"}
                            title={"hitchhiker"}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"May 13, 2023"}
                            text={"Slaughterhouse-Five by Kurt Vonnegut"}
                            title={"slaughter"}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"May 7, 2023"}
                            text={"A Clockwork Orange by Anthony Burgess"}
                            title={"orange"}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"May 5, 2023"}
                            text={"Brave New World by Aldous Huxley"}
                            title={"brave"}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"May 2, 2023"}
                            text={"War and Peace Volume 1 by Leo Tolstoy"}
                            title={"war"}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"April 15, 2023"}
                            text={"Applied Combinatorics by Mitchel T. Keller"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"April 10, 2023"}
                            text={"Calculus by Michael Spivak"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"April 6, 2023"}
                            text={"Linear Algebra Done Right by Sheldon Axler"}
                            title={"algebra"}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"March 24, 2023"}
                            text={"Surely You're Joking, Mr. Feynman!: Adventures of a Curious Character by Richard Feynman"}
                            title={"feynman"}
                            small={true}
                            pdf={null}
                        />
                    </div>
                </div>
            </div>
            <div style={{ marginBottom: "1rem", marginTop: "4rem" }}>
                <Title
                    date={""}
                    text={"Winter 2022-2023"}
                    title={""}
                    small={false}
                    pdf={null}
                />
                <div style={{ marginLeft: '2rem' }}>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"March 12, 2023"}
                            text={"The Old Man and the Sea by Ernest Hemingway"}
                            title={"sea"}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"March 4, 2023"}
                            text={"The God Delusion by Richard Dawkins"}
                            title={"delusion"}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"January 19, 2023"}
                            text={"East of Eden by John Steinbeck"}
                            title={"eden"}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Title
                            date={"December 25, 2022"}
                            text={"The Republic by Plato"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1.5rem' }}>
                        <Title
                            date={"December 19, 2022"}
                            text={"Essentials of Linguistics by Catherine Anderson"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                    <div style={{ marginTop: '1.5rem' }}>
                        <Title
                            date={"December 8, 2022"}
                            text={"The Sounds of Language: An Introduction to Phonetics by Henry Rogers"}
                            title={""}
                            small={true}
                            pdf={null}
                        />
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Timeline;